import {
  Case,
  CaseStatus,
  DriveTrain,
  SituationType,
} from '@/backend/models/Case';
import { User, UserRole, UserStatus } from '../../models/User';
import { WithId } from '../../types';
import { faker } from '@faker-js/faker';
import { EventBridgeEvent, SQSEvent } from 'aws-lambda';
import {
  RedgoOrderFormCaseUpdate,
  RedgoOrderFormCaseUpdateEvent,
} from 'shared';

const mapMockObjects = <T extends { id: string }>(list: T[]) =>
  list.reduce((map: Record<string, T>, obj) => {
    map[obj.id] = obj;
    return map;
  }, {});

const mockUserEmail1 = faker.internet.email();
const mockUserEmail2 = faker.internet.email();
const mockUserEmail3 = faker.internet.email();

export const mockUsers: WithId<User>[] = [
  {
    id: mockUserEmail1,
    email: mockUserEmail1,
    role: UserRole.ADMIN,
    name: faker.person.fullName(),
    status: UserStatus.UNCONFIRMED,
  },
  {
    id: mockUserEmail2,
    email: mockUserEmail2,
    role: UserRole.USER,
    name: faker.person.fullName(),
    status: UserStatus.UNCONFIRMED,
  },
  {
    id: mockUserEmail3,
    email: mockUserEmail3,
    role: UserRole.USER,
    name: faker.person.fullName(),
    status: UserStatus.REJECTED,
  },
];

export const mockUsersMap = mapMockObjects(mockUsers);

// used, where we want to ensure that case vehicles has the same registration plate
const vehicleRegistrationPlate = faker.vehicle.vrm();

export const mockCases: WithId<Case>[] = [
  {
    id: faker.string.uuid(),
    status: CaseStatus.CREATED,
    caseNumber: faker.string.alphanumeric(6).toUpperCase(),
    client: {
      fullName: faker.person.fullName(),
      phoneNumber: '+358501234567',
    },
    vehicle: {
      registrationPlate: vehicleRegistrationPlate,
      make: faker.vehicle.manufacturer(),
      model: faker.vehicle.model(),
      mileage: faker.number.int(),
      driveTrain: faker.helpers.arrayElement(Object.values(DriveTrain)),
    },
    location: {
      city: faker.location.city(),
      address: faker.location.streetAddress(),
      country: {
        name: faker.location.country(),
        alfa2: 'FI',
        alfa3: 'FIN',
      },
    },
    situation: {
      situationType: {
        id: faker.number.int({ min: 1, max: 20 }).toString(),
        type: faker.helpers.arrayElement(Object.values(SituationType)),
      },
      animalsOnBoard: false,
      hasTrailer: false,
      personCount: faker.number.int({ max: 8 }),
    },
    creator: {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      email: faker.internet.email(),
    },
  },
  {
    id: faker.string.uuid(),
    status: CaseStatus.CREATED,
    caseNumber: faker.string.alphanumeric(6).toUpperCase(),
    client: {
      fullName: faker.person.fullName(),
      phoneNumber: '+358501234567',
    },
    vehicle: {
      registrationPlate: vehicleRegistrationPlate,
      make: faker.vehicle.manufacturer(),
      model: faker.vehicle.model(),
      mileage: faker.number.int(),
      driveTrain: faker.helpers.arrayElement(Object.values(DriveTrain)),
    },
    location: {
      city: faker.location.city(),
      address: faker.location.streetAddress(),
      country: {
        name: faker.location.country(),
        alfa2: 'FI',
        alfa3: 'FIN',
      },
    },
    situation: {
      situationType: {
        id: faker.number.int({ min: 1, max: 20 }).toString(),
        type: faker.helpers.arrayElement(Object.values(SituationType)),
      },
      animalsOnBoard: false,
      hasTrailer: false,
      personCount: faker.number.int({ max: 8 }),
    },
    creator: {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      email: faker.internet.email(),
    },
  },
  {
    id: faker.string.uuid(),
    caseNumber: faker.string.alphanumeric(6).toUpperCase(),
    status: CaseStatus.CREATED,
    client: {
      fullName: faker.person.fullName(),
      phoneNumber: '+358501234567',
    },
    vehicle: {
      registrationPlate: faker.vehicle.vrm(),
      make: faker.vehicle.manufacturer(),
      model: faker.vehicle.model(),
      mileage: faker.number.int(),
      driveTrain: faker.helpers.arrayElement(Object.values(DriveTrain)),
    },
    location: {
      city: faker.location.city(),
      address: faker.location.streetAddress(),
      country: {
        name: faker.location.country(),
        alfa2: 'FI',
        alfa3: 'FIN',
      },
    },
    situation: {
      situationType: {
        id: faker.number.int({ min: 1, max: 20 }).toString(),
        type: faker.helpers.arrayElement(Object.values(SituationType)),
      },
      animalsOnBoard: false,
      hasTrailer: false,
      personCount: faker.number.int({ max: 8 }),
    },
    creator: {
      id: faker.string.uuid(),
      name: faker.person.fullName(),
      email: faker.internet.email(),
    },
  },
];

export const mockCasesMap = mapMockObjects(mockCases);

export const createMockSQSMessageEvent = (caseId: string): SQSEvent => ({
  Records: [
    {
      messageId: faker.string.uuid(),
      receiptHandle: faker.string.alphanumeric(5),
      body: JSON.stringify({
        ...mockCases[0],
        caseId: caseId,
      }),
      attributes: {
        ApproximateReceiveCount: '1',
        SentTimestamp: new Date().getTime().toString(),
        SenderId: faker.string.uuid(),
        ApproximateFirstReceiveTimestamp: new Date().getTime().toString(),
      },
      messageAttributes: {},
      md5OfBody: faker.string.uuid(),
      eventSource: 'aws:sqs',
      eventSourceARN: '',
      awsRegion: 'eu-north-1',
    },
  ],
});

export const createMockEventBridgeUpdateEvent = (
  caseId: string
): EventBridgeEvent<string, RedgoOrderFormCaseUpdate> => ({
  id: 'mockEventId',
  version: '1.0',
  account: '123456789012',
  time: new Date().toISOString(),
  region: 'eu-north-1',
  resources: ['resource1', 'resource2'],
  source: 'custom.source',
  'detail-type': RedgoOrderFormCaseUpdateEvent,
  detail: {
    id: faker.string.uuid(),
    status: 8,
    statusText: 'Case completed successfully',
    assistanceCaseNumber: 'DECDKX',
    caseId: caseId,
    fulfilledServices: [
      {
        jobId: faker.string.uuid(),
        relayedDate: '2023.07.21 20:16',
        repatriation: false,
        readyDate: '12.12.2022',
        serviceProviderName: 'ACTA',
        serviceType: 'rsaOrTrailer',
        trailerInsistedByCustomer: false,
        invoicedFromOtherParty: false,
      },
    ],
  },
});
